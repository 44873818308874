.navbar {
  padding: 10px;
}

.navbar .nav-link {
  padding: 0px;
}

.navbar .navbar-brand {
  height: 20%;
  padding: 0px;
  margin: 0%;
}

.navbar .navbar-brand img {
  height: 20%;
  padding: 0px;
}

.navbar .navbar-brand h3 {
  margin: 0;
}

.navbar .navbar-nav {
  flex-wrap: nowrap;
}

.container {
  padding: 0%;
}

  /* Option 1: Reduce the font size */
@media (max-width: 576px) {
  .navbar .brand-text {
    font-size: 14px;
  }
}

/* Option 2: Use the flex-wrap property */
@media (max-width: 576px) {
  .navbar .navbar-brand {
    flex-wrap: wrap;
  }
  .navbar .navbar-brand img {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

/* Option 3: Use media queries */
@media (max-width: 576px) {
  .navbar .navbar-brand {
    width: 100%;
    flex-basis: 100%;
  }
}
