.testimonial-card {
    max-width: 400px;
    max-height: 450px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .testimonial-card .card-body {
    margin: 10px;
    padding: 20px;
    text-align: center;
    height: 200px;
  }
  
  .testimonial-card .card-img-top {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto 10px;
  }
  
  .testimonial-card h5 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .testimonial-card p {
    margin: 0;
    font-size: 16px;
    color: #777;
  }
  
  .testimonial-card .btn {
    margin-top: 20px;
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    color: #fff;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .testimonial-card .btn:hover {
    background-color: #0069d9;
    cursor: pointer;
  }
  
  .my-5 .cars{
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
  }

  .testimonials .testimonials-item {
    height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}

.bg-image {
    height: 100%;
    width: 100%;
    position: absolute;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.5s ease;
    /* background-color: rgba(0, 0, 0, 0.5); */
}

.overlay.active {
    opacity: 1;
}

.overlay.active h3 {
    font-size: 3rem;
}

.overlay.active button {
    opacity: 1;
    transform: translateY(0);
}

.overlay h3 {
    font-size: 0;
    margin: 0;
    transition: all 0.5s ease;
}

.overlay button {
    opacity: 0;
    transition: all 0.5s ease;
    transform: translateY(50%);
}

.overlay:hover {
    transform: translate(-50%, -50%) scale(1.1);
}

.overlay:hover h3 {
    font-size: 50rem;
}

.overlay:hover button {
    opacity: 1;
    transform: translateY(0);
}

.carousel .carousel-item-next,
.carousel .carousel-item-prev,
.carousel .carousel-item.active {
    display: flex;
}

.carousel .carousel-item-next,
.carousel .carousel-item-prev {
    transform: translateX(0) !important;
}

.carousel .carousel-item-next.carousel-item-left,
.carousel .carousel-item-prev.carousel-item-right {
    transform: translateX(0) !important;
}

.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
    filter: invert(100%);
}

@media screen and (max-width: 767px) {
    .overlay h3 {
        font-size: 2rem;
    }
}


@media (max-width: 576px) {
    .carousel-inner .col-md-3 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    .carousel-inner .col-md-3 {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    .carousel-inner .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  
  @media (min-width: 993px) {
    .carousel-inner .col-md-3 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  
  .card-title-custom {
    color: rgb(44, 238, 118) !important;
    font-size: 20px;
    text-align: center;
    /* display: flex; */
    padding-top: 10px;
    padding-left: 10px;
    font-family: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif";
  }
  