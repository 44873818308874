.fadeIn {
    animation-name: fadeIn;
    animation-duration: 2s;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .slideIn {
    animation-name: slideIn;
    animation-duration: 1s;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .slideInButton {
    animation-name: slideInButton;
    animation-duration: 1s;
    animation-delay: 0.5s;
  }
  
  @keyframes slideInButton {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  