.cardimage {
  height: 40vh;
  width: auto;
  display: flex;
}

.carouselstyle {
  height: auto;
  width: auto;
}

.cards {
  max-width: 100%;
  height: auto;
}

.cartext {
  text-align: right;
  padding: 10px;
}