.screen {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    scroll-behavior: smooth;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.screen--hidden {
    opacity: 0;
    /* pointer-events: none; */
    transition: opacity 0.5s ease-out;
    display: none;
}

.screen__text {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}


.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  
.typewriter {
    overflow: hidden;
    font-size: large;
    /* Ensures the text is not revealed until the animation */
    border-right: 1em solid orange;
    /* The typewriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    margin: 0 auto;
    /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;
    /* Adjust as needed */
    animation:
        typing 3s steps(28),
        blink-caret .75s infinite;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 50%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange;
    }
}