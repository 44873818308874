.services {
    background-color: #f4f8fc;
}

/* adjust font size and padding for smaller screens */
@media only screen and (max-width: 600px) {
    body {
      font-size: 14px; /* adjust font size */
    }
    h1 {
      font-size: 32px; /* adjust heading font size */
    }
    p {
      padding: 10px; /* add padding to paragraphs */
      margin-bottom: 20px; /* add margin to bottom of paragraphs */
    }
  }
  

  @media (max-width: 767px) {
    .col-sm-4 .my-3 {
      overflow-wrap: break-word;
    }
  }