.carousel .carousel-item {
    height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}

@media (max-width: 576px) {
    .carousel-inner .col-md-3 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    .carousel-inner .col-md-3 {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    .carousel-inner .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  
  @media (min-width: 993px) {
    .carousel-inner .col-md-3 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }