.carousel .carousel-item {
  height: 70vh !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.bg-image {
  height: 100%;
  width: 100%;
  position: absolute;
}

.overlay {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay.active {
  opacity: 1;
}

.overlay.active h3 {
  font-size: 3rem;
}

.overlay.active button {
  opacity: 1;
  transform: translateY(0);
}

.overlay h3 {
  font-size: 0;
  margin: 0;
  transition: all 0.5s ease;
}

.overlay button {
  opacity: 0;
  transition: all 0.5s ease;
  transform: translateY(50%);
}

.overlay:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.overlay:hover h3 {
  font-size: 50rem;
}

.overlay:hover button {
  opacity: 1;
  transform: translateY(0);
}

.carousel .carousel-item-next,
.carousel .carousel-item-prev,
.carousel .carousel-item.active {
  display: flex;
}

.carousel .carousel-item-next,
.carousel .carousel-item-prev {
  transform: translateX(0) !important;
}

.carousel .carousel-item-next.carousel-item-left,
.carousel .carousel-item-prev.carousel-item-right {
  transform: translateX(0) !important;
}

.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  filter: invert(100%);
}

@media screen and (max-width: 767px) {
  .overlay h3 {
    font-size: 2rem;
  }
}


.overlay-black {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.text2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #070000;
  text-align: center;
  font-family: 'Cambria', 'Georgia', serif;
}

.text h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.text p {
  font-size: 1.5rem;
}

/* .text-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    font-family: 'Cambria', 'Georgia', serif;
} */



.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-45deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  animation: wave 2s linear infinite;
  font-size: 40px;
  display: inline-block;
}

@keyframes wave {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}





.carousel-caption {
  position: absolute;
  bottom: 0;
  transform: translate(7%, 0%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #c70000;
  width: 80%;
  transition: opacity 0.5s ease;
  opacity: 1;
  padding-right: 0px;
  justify-content: flex-start;
  text-size-adjust: auto;
  /* overflow: auto; */
  word-wrap: break-word;
  /* word-break: break-all; */
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}


.carousel-caption h3 {
  font-size: 1.5rem;
  /* Set the font size to 1.5 rem */
  text-align: center;
  padding: 1rem;
  /* max-width: 90%; Set a max width to ensure text wraps when it reaches this limit */
  overflow:wrap;
  /* Hide text overflow */
  text-overflow: ellipsis;
  /* Add ellipsis at the end of the text */
  /*  Prevent text from wrapping to the next line */
  /* white-space: nowrap;  */
  color:rgb(255, 255, 255);

}

.carousel-caption p {
  font-size: 1.5rem;
  text-size-adjust: auto;
  text-align: left;
}

.bg-image:hover .carousel-caption {
  opacity: 1;
}

.carousel-caption>button {
  margin-top: 20px;

}

.carousel-item.active .bg-image {
  animation: slide 1s linear infinite;
}



.read-more {
  position: relative;
  overflow: hidden;
  font-size: 24px;
  padding: 10px;
  color: white;
}

.curtain {
  position: absolute;
  left: -100%;
  top: 0;
  width: 200%;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.5);
  transition: all 0.5s ease-in-out;
}

.curtain-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.5);
  transform-origin: top left;
  transition: all 0.5s ease-in-out;
}

.curtain-bottom {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.5);
  transform-origin: top right;
  transition: all 0.5s ease-in-out;
}

.text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-99%, -50%);
  color: #fff;
  font-size: 20px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.read-more:hover .curtain {
  left: 0;
}

.read-more:hover .curtain-top {
  transform: rotate(-45deg);
  transform-origin: top left;
}

.read-more:hover .curtain-bottom {
  transform: rotate(45deg);
  transform-origin: top right;
}

.read-more:hover .text {
  opacity: 1;
}