.foot {
    margin: 0%;
}
footer {
    background-color: #333;
    color: #fff;
    padding: 0 0;
    margin: 0%;
  }
  
  footer a {
    color: #fff;
  }
  
  .footer-logo {
    width: 50%;
    max-width: 200px;
    margin-bottom: 1rem;
  }
  
  .footer-links li {
    display: inline-block;
    margin-right: 1rem;
  }
  
  .social-media {
    display: flex;
    margin-bottom: 1rem;
  }
  
  .social-media li {
    margin-right: 1rem;
  }
  
  .contact-info {
    text-align: center;
  }
  
  .contact-info p {
    margin-bottom: 0.5rem;
  }
  
  .cprights {
    text-align: center;
  }